exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assets-asset-js": () => import("./../../../src/pages/assets/asset.js" /* webpackChunkName: "component---src-pages-assets-asset-js" */),
  "component---src-pages-edu-edu-page-item-js": () => import("./../../../src/pages/edu/eduPageItem.js" /* webpackChunkName: "component---src-pages-edu-edu-page-item-js" */),
  "component---src-pages-edu-edu-slide-section-js": () => import("./../../../src/pages/edu/eduSlideSection.js" /* webpackChunkName: "component---src-pages-edu-edu-slide-section-js" */),
  "component---src-pages-edu-index-js": () => import("./../../../src/pages/edu/index.js" /* webpackChunkName: "component---src-pages-edu-index-js" */),
  "component---src-pages-edu-intro-section-js": () => import("./../../../src/pages/edu/introSection.js" /* webpackChunkName: "component---src-pages-edu-intro-section-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-alumni-js": () => import("./../../../src/templates/alumni.js" /* webpackChunkName: "component---src-templates-alumni-js" */),
  "component---src-templates-alumnus-js": () => import("./../../../src/templates/alumnus.js" /* webpackChunkName: "component---src-templates-alumnus-js" */),
  "component---src-templates-custom-layout-js": () => import("./../../../src/templates/customLayout.js" /* webpackChunkName: "component---src-templates-custom-layout-js" */),
  "component---src-templates-edu-page-js": () => import("./../../../src/templates/eduPage.js" /* webpackChunkName: "component---src-templates-edu-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-registration-js": () => import("./../../../src/templates/registration.js" /* webpackChunkName: "component---src-templates-registration-js" */)
}

